import React from 'react'
import { Link } from 'gatsby'
import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import Layout from '/src/components/layout'
import HeroImage from '/src/components/layout/HeroImage'

const RoofingProductsPage = () => {
  return (
    <Layout hero={<HeroImage title="Roofing Products" />} mainContainer={{fluid: "xxl"}}>
      <div className="content-row-container">
        <Row className="d-flex justify-content-center mt-4">
          <Col sm={10}>
            <Row className="mb-4">
              <Col sm={12}>
                <p>
                  Orrcon Steel supply a range of metal roofing & rainwater products.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center content-row single">
          <Col xs={10}>
            <Row className="d-flex align-items-center">
              <Col sm={3}>
                <div className="title-container p-3">
                  <Link to="/product-info/roofing-products">
                    <h2 className="mb-4">ROOFING PRODUCTS</h2>
                  </Link>
                </div>
              </Col>
              <Col sm={9}>
                <Row>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/roofing-products" className="mb-2">
                        Roofing & Wall Profiles
                      </Link>
                      <p>Orrcon Steel panels and profiles offer an attractive.</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/roofing-products" className="mb-2">
                        Rainwater Products
                      </Link>
                      <p>Orrcon Steel supplies a range of rainwater product needs.</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/roofing-products" className="mb-2">
                        Purlins and Girts
                      </Link>
                      <p>Purlins & girts are made from high tensile steel.</p>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/roofing-products" className="mb-2">
                        Battens
                      </Link>
                      <p>We supply a range of steel roofing battens.</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

export default RoofingProductsPage

export const Head = () => <title>Roofing Products</title>
